import {
    AuditOutlined,
    BoxPlotOutlined,
    BranchesOutlined,
    ProjectOutlined,
    SettingOutlined,
    CloudServerOutlined,
    AppstoreOutlined,
    ApiOutlined,
    PicLeftOutlined,
    UserOutlined,
    GithubFilled
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import classes from './Menu.module.css';
import MachineIcon from "../CustomIcons/MachineIcon";
import ProjectIcon from "../CustomIcons/ProjectIcon";
import StaffIcon from "../CustomIcons/StaffIcon";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {useData} from "../../DataContext";

// const { SubMenu } = Menu;

// submenu keys of first level
const pathNameKeyMapper = {
    '': 'profile',
    '/': 'profile',
    '/user/profile': 'profile',
    '/users': 'user',
    '/user/detail': 'user',
    '/branches': 'branch-settings',
    '/settings': 'general-settings',
    '/machine/detail': 'machines',
    '/machines': 'machines',
    '/project/detail': 'projects',
    '/projects': 'projects',
    '/manifest/detail': 'projects',
    '/user_permissions': 'user-permissions',
    '/system_infos': 'system-infos',
    '/auth_apps': 'auth-apps',
    '/api_servers': 'api-servers',
    '/api_events': 'api-events',
    '/api_tasks': 'api-tasks',
}
const getAdminMenu = (props, t) => {
    return [
        <Menu.Item key="profile" icon={<UserOutlined style={{color: "#377EF3"}}/>} className={classes.menuItemStyle}>
            <Link to='/user/profile'>{t('menu_0')}</Link>
        </Menu.Item>,
        <Menu.Item key="projects" icon={<ProjectIcon/>} className={classes.menuItemStyle}>
            <Link to='/projects'>{t('menu_1')}</Link>
        </Menu.Item>,
        <Menu.Item key="machines" icon={<MachineIcon/>} className={classes.menuItemStyle}>
            <Link to='/machines'>{t('menu_2')}</Link>
        </Menu.Item>,
        <Menu.Item key="user" icon={<StaffIcon/>} className={classes.menuItemStyle}>
            <Link to='/users'>{t('menu_3')}</Link>
        </Menu.Item>,
        <Menu.Item key="branch-settings"  icon={<BranchesOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/branches'>{t('menu_4')}</Link>
        </Menu.Item>,
        <Menu.Item key="general-settings" icon={<SettingOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/settings'>{t('menu_5')}</Link>
        </Menu.Item>,
    ];
}

const systemUserMenu = (props, t) => {
    return [
        // <Menu.Item key="projects" icon={<ProjectIcon/>} className={classes.menuItemStyle}>
        //     <Link to='/projects'>{t('menu_1')}</Link>
        // </Menu.Item>,
        // <Menu.Item key="staffs" icon={<UserOutlined/>} className={classes.menuItemStyle}>
        //     <Link to='#'>Staffs</Link>
        // </Menu.Item>,
        // <Menu.Item key="machines" icon={<MachineIcon/>} className={classes.menuItemStyle}>
        //     <Link to='/machines'>{t('menu_2')}</Link>
        // </Menu.Item>,
        // <Menu.Item key="user" icon={<StaffIcon/>} className={classes.menuItemStyle}>
        //     <Link to='/users'>{t('menu_3')}</Link>
        // </Menu.Item>,
        // <Menu.Item key="branch-settings"  icon={<BranchesOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
        //     <Link to='/branches'>{t('menu_4')}</Link>
        // </Menu.Item>,
        <Menu.Item key="user-permissions" icon={<AuditOutlined/>} className={classes.menuItemStyle}>
            <Link to='/user_permissions'>{t('menu_6')}</Link>
        </Menu.Item>,
        // <Menu.Item key="general-settings" icon={<SettingOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
        //     <Link to='#'>{t('menu_5')}</Link>
        // </Menu.Item>,
        <Menu.Item key="auth-apps" icon={<AppstoreOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/auth_apps'>{t('menu_7')}</Link>
        </Menu.Item>,
        <Menu.Item key="api-servers" icon={<CloudServerOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/api_servers'>{t('menu_8')}</Link>
        </Menu.Item>,
        <Menu.Item key="api-events" icon={<ApiOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/api_events'>{t('menu_9')}</Link>
        </Menu.Item>,
        <Menu.Item key="api-tasks" icon={<PicLeftOutlined style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/api_tasks'>{t('menu_10')}</Link>
        </Menu.Item>,
        <Menu.Item key="system-infos" icon={<GithubFilled style={{color: '#377EF3'}}/>} className={classes.menuItemStyle}>
            <Link to='/system_infos'>{t('menu_11')}</Link>
        </Menu.Item>,
    ];
}

const getUserMenu = (props, t) => {
    return [
        <PermissionChecker requiredPermissions={['user-detail']}>
            <Menu.Item key="profile" icon={<UserOutlined style={{color:"#377EF3"}}/>} className={classes.menuItemStyle}>
                <Link to='/user/profile'>{t('menu_0')}</Link>
            </Menu.Item>
        </PermissionChecker>,
        <PermissionChecker requiredPermissions={['project-list']}>
            <Menu.Item key="projects" icon={<ProjectOutlined/>} className={classes.menuItemStyle}>
                <Link to='/projects'>{t('menu_1')}</Link>
            </Menu.Item>
        </PermissionChecker>,
        <PermissionChecker requiredPermissions={['machine-list']}>
            <Menu.Item key="machines" icon={<BoxPlotOutlined/>} className={classes.menuItemStyle}>
                <Link to='/machines'>{t('menu_2')}</Link>
            </Menu.Item>
        </PermissionChecker>,
        <PermissionChecker requiredPermissions={['user-list']}>
            <Menu.Item key="user" icon={<StaffIcon/>} className={classes.menuItemStyle}>
                <Link to='/users'>{t('menu_3')}</Link>
            </Menu.Item>
        </PermissionChecker>,
    ];
}

function buildMenu(props, auth, t) {
    if(auth.is_superuser){
        return systemUserMenu(props, t)
    }
    else if( auth.is_admin){
        return getAdminMenu(props, t);
    }
    else {
        return getUserMenu(props, t);
    }
}


const deriveMenuKey = (pathname) => {
    // Remove trailing slash if present
    const cleanedPath = pathname.replace(/\/$/, '');
    return pathNameKeyMapper[cleanedPath] || 'profile';
};

export const SiderMenu = ({props}) => {
    const {sharedData} = useData();
    const is_superuser = parseInt(sharedData.is_superuser||"0") || parseInt(window.sessionStorage.getItem('isSuperuser')||"0") || null;
    const is_adminuser = parseInt(sharedData.is_admin||"0") || parseInt(window.sessionStorage.getItem('isAdminUser')||"0") || null;
    const auth = {is_superuser: is_superuser, is_admin:is_adminuser}
    const { t } = useTranslation();
    let location = useLocation();
    const currentPath = deriveMenuKey(location.pathname);
    const [current, setCurrent] = useState(currentPath);

    useEffect(() => {
        if (current !== currentPath) {
            setCurrent(currentPath);
        }
    }, [location.pathname, currentPath, current]);

    const menus = buildMenu(props, auth, t);

    const handleMenuClick=(e)=>{
        setCurrent(e.key)
    }

    return (
        <Menu mode="inline" onClick={handleMenuClick} selectedKeys={[current]} className={classes.menu}>
            {menus}
        </Menu>
    );
};
