import React, {useEffect, useState} from "react";
import { Button, message, Table, Row, Col } from "antd";
import csvtojson from "csvtojson";
import axios from "axios";
import {DownloadOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";

const CSVFIlePreview = ({ url, columns, title, downloadFileName, headers, handleDownloadButtonClick, requiredPermission}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const fetchData = () => {
    setLoading(true);
    if(url) {
      try {
        axios.get(url).then((response) => {
          const csvString = response.data;
          csvtojson({headers: headers}).fromString(csvString).then((value) => {
            setData(value);
          });

        }).catch((err)=>{
        });

      } catch (error) {
        message.error(t("csvfile_load_error"));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(()=>{
    if(url) {
      fetchData();
    }
  }, [url])

  const downloadCsv = async () => {
    if(handleDownloadButtonClick){
      handleDownloadButtonClick()
    }
    else {
      try {
        const response = await axios.get(url, {responseType: "blob"});
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", downloadFileName || "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        message.error(t("csv_file_download_error"));
      }
    }
  };

    // Adjust columns for better responsiveness
  const adjustedColumns = columns.map(col => ({
    ...col,
    onCell: () => ({
      style: {
        minWidth: 150, // Adjust this value as needed
      },
    }),
  }));

  return (
      <>
        <Row style={{justifyContent: 'space-between', alignItems:'start', marginBottom:'10px'}}>
          <Col style={{fontWeight:"bold", fontSize: 15}}>
            {title}
          </Col>
          <Col>
            <PermissionChecker requiredPermissions={requiredPermission || []}>
              <Button style={{border: "none", background:'none'}} onClick={()=>downloadCsv()}><DownloadOutlined /></Button>
            </PermissionChecker>
          </Col>
        </Row>
        <Row style={{overflowY: 'scroll', width: '100%', height: '100%'}}>
          {data.length > 0 && (
              <Table
                  loading={loading}
                  columns={adjustedColumns}
                  dataSource={data}
                  rowKey="_id"
                  pagination={false}
                  style={{width: '100%'}}
                  sticky={true}
                  scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
              />
          )}
        </Row>
      </>
  );
};

CSVFIlePreview.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  downloadFileName: PropTypes.string,
};

export default CSVFIlePreview;
