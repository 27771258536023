import AddPageModal from "../../components/AddPage/AddPage";
import {useState, useEffect} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const ProjectMachineAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, project, reload})=>{
    const [initialValues, setInitialValues] = useState({'project': project.project_name})
    useEffect(() => {
        // Set the initial values of the form fields
        setInitialValues({'project': project.project_name})
    }, [reload]);
    const { t } = useTranslation();
    const config = {
        addUrl: '/api/v1/projects/machines/add/',
        editUrl: '',
        dataUrl: '',
        pageTitle: t('project_machine_allocationpage_title', {project_name: project.project_name}),
        colNo: 1,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: t('addpage_save_button_text'),
        addSuccessMessage: t('project_machine_allocation_successful_text'),
        modalSize: 600,
        fields: [
            {
                name: 'project',
                label: t('machineschedule_project_field'),
                placeholder: t('machineschedule_project_field'),
                isRequired: true,
                requiredMessage: t('machineschedule_project_required_text'),
                type: 'input',
                colNo: 1,
                order: 1,
                extraProps: {
                    disabled: true,
                }
            },
            {
                name: 'machine_list',
                label: t('machine_listpage_title'),
                placeholder: t('select_placeholder'),
                isRequired: true,
                requiredMessage: t('machineschedule_machine_required_text'),
                type: 'select',
                extraProps: {
                    url: `/api/v1/machines/combo/?exclude_project=${project.id}&active=1`,
                    mode: 'multiple'
                },
                colNo: 1,
                order: 2
            }
        ]
    }

    const onFinish=(values, _, form)=>{
        values.project = project.id;
        http.post(config.addUrl, values).then((resp) => {
                        if (resp.status === 201) {
                            message.success(config.addSuccessMessage || resp.data.message || t('project_machine_allocation_successful_text'));
                            if (onSuccessSubmit) {
                                onSuccessSubmit();
                                form.resetFields();
                            } else if (onClose) {
                                onClose();
                                form.resetFields();
                            }
                        }
                    }).catch((error) => {
                        message.error(t('project_machine_allocation_failed_text'),)
                        if (onErrorSubmit) {
                            onErrorSubmit();
                            form.resetFields();
                        }
                    })
    }


    return <AddPageModal config={config} onFinish={onFinish} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} initialValues={initialValues} reload={reload}/>

}

export default ProjectMachineAddPage;