import {Input, Upload} from "antd";
import React, {useEffect} from 'react';
import FileUploadIcon from "../CustomIcons/FileUploadIcon";
import {useTranslation} from "react-i18next";
import { CloseCircleOutlined } from '@ant-design/icons';

const MyUploadInput = ({ value, onChange, placeholder }) => {
    const [fileName, setFileName] = React.useState(value);
    const { t } = useTranslation();
    const handleFileChange = (info) => {
        setFileName(info.file.name);
        onChange(info);
    };
    useEffect(()=>{
        if(value) {
            if (value instanceof File ) {
                setFileName(value.name)
            }
            else{
                setFileName(value);
            }
        }
    }, [value])

    const clearInput = () => {
        setFileName('');
        onChange(null)
    };

    return (
        <Upload
            accept=".pdf,.doc,.docx,.txt,.png,.jpg,.jpeg,.dxf,.csv,.xml,.json"
            beforeUpload={() => false}
            onChange={handleFileChange}
            showUploadList={false}
        >
            <Input
                prefix={<FileUploadIcon />}
                // disabled={true}
                placeholder={placeholder || t('select_file_placeholder')}
                readOnly
                value={fileName || ''}
                suffix={
                    fileName && (
                        <span
                            style={{
                                cursor: 'pointer',
                                color: 'rgba(0, 0, 0, 0.25)',
                            }}
                            onClick={clearInput}
                        >
              <CloseCircleOutlined />
            </span>
                    )
                }
            />
        </Upload>
    );
};

export default MyUploadInput;