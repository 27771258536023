import React, {useEffect, useState} from "react";
import {Col, Row, Statistic, Collapse, Table, Space, Typography, message} from 'antd';
import classes from './HomePage.module.css'
import http from "../../http";
import {DeleteOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
const { Panel } = Collapse;

const HomePage = (props) => {
    // const { t } = useTranslation();
    // const [cards, setCards] = useState({});
    // const [machineExpiryData, setMachineExpiryData] = useState([]);
    // const [manifestChangeData, setManifestChangeData] = useState([]);
    // const [runningManifestData, setRunningManifestData] = useState([]);
    //
    // const machineExpiryColumns = [
    //     // { title: 'ID', dataIndex: 'machine_id', key: 'id', hidden: true},
    //     // { title: 'Machine', dataIndex: 'machine', key: 'name'},
    //     { title: 'Number', dataIndex: 'number', key: 'number', width: '25%'},
    //     { title: 'Owner', dataIndex: 'owner', key: 'owner', ellipsis: true,  width: '30%',
    //         render:(text)=>(
    //             // <Tooltip title={text} placement={'leftTop'}>
    //             <div>{text}</div>
    //             // </Tooltip>
    //         )},
    //     { title: 'Expire Date', dataIndex: 'expiry_date', key: 'end_date', ellipsis: true,  width: '32%'},
    //     {title: '', key:'action', render:(record)=>(
    //             <Space>
    //                 <Typography.Link onClick={()=>message.error("Not Implemented Yet")}><DeleteOutlined style={{color: new Date(record.expiry_date) < new Date() ? 'white': 'black'}}/></Typography.Link>
    //             </Space>
    //         ),
    //         width: '12%'
    //     }
    // ]
    // const manifestChangeHistoryColumns = [
    //     // { title: 'ID', dataIndex: 'machine_id', key: 'id', hidden: true},
    //     // { title: 'Machine', dataIndex: 'machine', key: 'name'},
    //     { title: 'Name', dataIndex: 'name', key: 'name', width: '25%', ellipsis: true,
    //         render: (text)=>{
    //             // return <Tooltip title={text} placement={'leftTop'}>
    //             return <div>{text}</div>
    //             // </Tooltip>
    //         }
    //     },
    //     { title: 'Event', dataIndex: 'event', key: 'event', ellipsis: true,  width: '30%',
    //         render: (text) => {
    //             if(text) {
    //                 const parts = text.split(',');
    //                 const lastPart = parts.pop(); // Remove and store the last part
    //                 const lastPartParts = lastPart.split(' '); // Split the last part by space
    //                 const translatedParts = parts.map(part => t(part)); // Translate individual parts
    //                 const translatedLastPart = t(lastPartParts[0]); // Translate the first part of the last item
    //                 const finalString = translatedParts.join(',') + ' ' + translatedLastPart; // Concatenate
    //                 return <div>{finalString + ' ' + t(lastPartParts[1])}</div>
    //             }
    //             else{
    //                 return <div></div>
    //             }
    //         },
    //     },
    //     {
    //         title: t('manifest_changehostory_col3'),
    //         dataIndex: 'triggered_by_text',
    //         key: 'triggered_by_text',
    //         ellipsis: true,
    //         render: (text)=>{
    //             // return <Tooltip title={text} placement={'leftTop'}>
    //             return <div>{text}</div>
    //             // </Tooltip>
    //         }
    //     },
    //     {
    //         title: t('manifest_changehostory_col1'),
    //         dataIndex: 'triggered_date_time',
    //         key: 'date',
    //         ellipsis: true,
    //         render: (text)=>{
    //             // return <Tooltip title={text} placement={'leftTop'}>
    //             const utcDate = new Date(text); // Assuming datetime value from backend
    //             const localDate = utcDate.toLocaleString(); // Convert to local timezone
    //             return <div>{localDate}</div>
    //             // </Tooltip>
    //         }
    //     },
    //
    // ]
    // const runningManifestColumns = [
    //     // { title: 'ID', dataIndex: 'machine_id', key: 'id', hidden: true},
    //     // { title: 'Machine', dataIndex: 'machine', key: 'name'},
    //     { title: 'Manifest', dataIndex: 'name', key: 'number'},
    //     { title: 'Machine', dataIndex: 'machine', key: 'owner', ellipsis: true,
    //         render:(text)=>(
    //             <div>{text}</div>
    //         )},
    //     { title: 'Project', dataIndex: 'project', key: 'end_date', ellipsis: true},
    //
    // ]
    //
    // useEffect(()=>{
    //     http.get('/api/v1/dashboard/info_cards/').then((resp)=>{
    //         setCards(resp.data);
    //     });
    //     http.get('/api/v1/dashboard/portlets/upcoming_machine_expiry_list/').then((resp)=>{
    //         setMachineExpiryData(resp.data);
    //     })
    //     http.get('/api/v1/dashboard/portlets/manifest_change_history_list/').then((resp)=>{
    //         setManifestChangeData(resp.data);
    //     })
    //     http.get('/api/v1/dashboard/portlets/running_manifest_list/').then((resp)=>{
    //         setRunningManifestData(resp.data);
    //     })
    //     // Cleanup function (optional)
    //     return () => {
    //         // Perform cleanup if needed
    //     };
    // }, [])
    return (
        <>Welcome</>
        // <>
        //     <Row gutter={16}>
        //         <Col span={6}>
        //             <Statistic
        //                 style={{background: 'white', height: '100%'}}
        //                 title={<div style={{textAlign: 'center', fontWeight: 'bold',background: '#5786EC', color: 'white'}}>Running Projects</div>}
        //                 value={cards.running_projects || 0}
        //                 valueStyle={{ textAlign: 'center', fontSize: '2em', fontWeight: 'bold', height: '8vh' }}
        //             />
        //         </Col>
        //         <Col span={6}>
        //             <Statistic
        //                 style={{background: 'white', height: '100%'}}
        //                 title={<div style={{textAlign: 'center', fontWeight: 'bold', background: 'magenta', color: 'white'}}>Running Machines</div>}
        //                 value={cards.running_machines || 0}
        //                 valueStyle={{ textAlign: 'center', fontSize: '2em', fontWeight: 'bold', height: '8vh' }}
        //             />
        //         </Col>
        //         <Col span={6}>
        //             <Statistic
        //                 style={{background: 'white', height: '100%'}}
        //                 title={<div style={{textAlign: 'center', fontWeight: 'bold', background: 'limegreen', color: 'white'}}>Open Tickets</div>}
        //                 value={cards.open_tickets || 0}
        //                 valueStyle={{ textAlign: 'center', fontSize: '2em', fontWeight: 'bold', height: '8vh' }}
        //             />
        //         </Col>
        //         <Col span={6}>
        //             <Statistic
        //                 style={{background: 'white', height: '100%'}}
        //                 title={<div style={{textAlign: 'center', fontWeight: 'bold', background: 'coral', color: 'white'}}>Registered Staffs</div>}
        //                 value={cards.registered_staffs || 0}
        //                 valueStyle={{ textAlign: 'center', fontSize: '2em', fontWeight: 'bold', height: '8vh' }}
        //             />
        //         </Col>
        //     </Row>
        //     <div style={{width:'100%', clear: 'both', margin: '24px 0', minWidth:'100%'}}/>
        //     <Row gutter={16}>
        //         <Col span={6}>
        //             <Statistic
        //                 style={{background: 'white', height: '100%'}}
        //                 title={<div style={{textAlign: 'center', fontWeight: 'bold',background: 'blue', color: 'white'}}>Completed Projects</div>}
        //                 value={cards.completed_projects || 0}
        //                 valueStyle={{ textAlign: 'center', fontSize: '2em', fontWeight: 'bold', height: '8vh' }}
        //             />
        //         </Col>
        //
        //     </Row>
        //     <div style={{width:'100%', clear: 'both', margin: '24px 0', minWidth:'100%'}}/>
        //     <Row gutter={16}>
        //         <Col span={8}>
        //             <Collapse accordion>
        //                 <Panel header={<div className={classes.portletHeader}>Upcoming Machine Expiry</div>} key="1" className={classes.machineReturnPortletBG}>
        //                     <div style={{height: '200px', overflowY:'auto'}}>
        //                         <Table
        //                             className="custom-table"
        //                             dataSource={machineExpiryData}
        //                             columns={machineExpiryColumns}
        //                             rowClassName={(record) => {
        //                                 if (new Date(record.expiry_date) < new Date()) {
        //                                     return 'red-row'
        //                                 }
        //                             }
        //                             }
        //                             pagination={false} />
        //                     </div>
        //                 </Panel>
        //             </Collapse>
        //         </Col>
        //         <Col span={8}>
        //             <Collapse accordion>
        //                 <Panel header={<div className={classes.portletHeader}>Recent Manifest Change</div>} key="2" className={classes.manifestChangePortletBG}>
        //                     <div style={{height: '200px', overflowY:'auto'}}>
        //                         <Table dataSource={manifestChangeData} columns={manifestChangeHistoryColumns} pagination={false} />
        //                     </div>
        //                 </Panel>
        //             </Collapse>
        //         </Col>
        //         <Col span={8}>
        //             <Collapse accordion>
        //                 <Panel header={<div className={classes.portletHeader}>Running Manifest</div>} key="3" className={classes.runningManifestPortlet}>
        //                     <div style={{height: '200px', overflowY:'auto'}}>
        //                         <Table
        //                             dataSource={runningManifestData}
        //                             columns={runningManifestColumns}
        //                             pagination={false}
        //                         />
        //                     </div>
        //                 </Panel>
        //             </Collapse>
        //         </Col>
        //
        //     </Row>
        // </>
    );
};

export default HomePage;
