import {Divider, Row, Col, Space, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import MachineListPage from "../Machines/MachineListPage";
import {useTranslation} from "react-i18next";

const UserDetailPage=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location;
    const { t } = useTranslation();
    const [isValidState, setValidState] = useState(!!state?.user);

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
    }, [state])

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state.breadcrumbs){
            state.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{state.user.full_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/users'}>{t('staff_listpage_title')}  </Link> >  {state.user.full_name}</>;
    }

    if(isValidState) {
        return (
            <>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{state.user.full_name}</h1></Col>
                        <Col>{t('staff_username_field')} : {state.user.username}</Col>
                        <Col>{t('staff_email_field')} : {state.user.email}</Col>
                        <Col>{t('staff_mobile_field')} : {state.user.mobile}</Col>
                        <Col>{t('staff_slackid_field')} : {''}</Col>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MachineListPage pageSize={6} url={`/api/v1/machines/?operator=${state.user.id}`}
                                             title={t('staff_machine_list_title')}/>
                        </div>
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default UserDetailPage;
