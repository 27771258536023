import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const MachineAssignmentAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, project, reload})=>{
    const [initialValues, setInitialValues] = useState({project: project.id});
    const [machineID, setMachineID] = useState(null);
    const [manifestID, setManifestID] = useState(null);
    const [machineURL, setMachineURL] = useState(`/api/v1/machines/combo/?project=${project.id}&active=1`);
    const [manifestURL, setManifestURL] = useState(`/api/v1/projects/manifests/combo/?project=${project.id}`);
    const defaultValues = {project: project.id};
    const { t } = useTranslation();
    const handleMachineChange=(value)=>{
        setMachineID(value)
    }

    const handleManifestChange=(value)=>{
        setManifestID(value)
    }

    const modalClose=()=>{
        setManifestID(null);
        setMachineID(null);
        onClose()
    }

    const config = {
        addUrl: '/api/v1/projects/machine-assignments/add/',
        editUrl: edit ? `/api/v1/projects/machine-assignments/${editItem?.id}/edit/` : '',
        dataUrl: edit ? `/api/v1/projects/machine-assignments/${editItem?.id}/` : '',
        pageTitle: !edit ? t('machineschedule_addpage_title') : t('machineschedule_editpage_title'),
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('machineschedule_successful_add_message') : t('machineschedule_successful_edit_message'),
        modalSize: 500,
        fields: [
            {
                name: 'project',
                label: t('machineschedule_project_field'),
                placeholder: t('machineschedule_project_field'),
                isRequired: true,
                requiredMessage: t('machineschedule_project_required_text'),
                type: 'select',
                extraProps: {
                    url: `/api/v1/projects/combo/?id=${project.id}`,
                    value: project.id
                },
                colNo: 1,
                order: 1
            },
            {
                name: 'machine',
                label: t('machineschedule_machine_field'),
                placeholder: t('machineschedule_machine_field'),
                isRequired: true,
                requiredMessage: t('machineschedule_machine_required_text'),
                type: 'select',
                extraProps: {
                    url: machineURL,
                    onChange: handleMachineChange
                },
                colNo: 1,
                order: 2
            },
            {
                name: 'manifest',
                label: t('machineschedule_manifest_field'),
                placeholder: t('machineschedule_manifest_field'),
                isRequired: true,
                requiredMessage: t('machineschedule_manifest_required_text'),
                type: 'select',
                extraProps: {
                    url: manifestURL,
                    onChange: handleManifestChange
                },
                colNo: 1,
                order: 3
            },
            {
                name: 'operator',
                label: t('machineschedule_operator_field'),
                placeholder: t('machineschedule_operator_field_placeholder'),
                isRequired: false,
                type: 'select',
                extraProps: {
                    url: '/api/v1/auth/users/combo/'
                },
                requiredMessage: '',
                colNo: 1,
                order: 4
            },
            {
                name: 'from_date',
                label: t('machineschedule_start_field'),
                placeholder: t('machineschedule_start_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'datetime',
                colNo: 1,
                order: 5,
            },
            {
                name: 'to_date',
                label: t('machineschedule_end_field'),
                placeholder: t('machineschedule_end_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                extraProps:{
                    offset: 7
                },
                type: 'datetime',
                colNo: 1,
                order: 6
            }
        ]
    }
    const onFinish=(values)=>{
        console.log(values)
    }
    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/projects/machine-assignments/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                console.log(resp.data)
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])
    useEffect(() => {
        // Update the URL for the "Manifest" field whenever machineID changes
        const newUrl = `/api/v1/projects/manifests/combo/?project=${project.id}&machine_type=${machineID}`;
        setManifestURL(newUrl)
    }, [machineID]);

    useEffect(() => {
        // Update the URL for the "Manifest" field whenever machineID changes
        const newUrl = `/api/v1/machines/combo/?project=${project.id}&active=1&manifest_type=${manifestID}`;
        setMachineURL(newUrl)
    }, [manifestID]);

    return <AddPageModal config={config} visible={visible} onClose={modalClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues} defaultValues={defaultValues}/>

}

export default MachineAssignmentAddPage;