import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {Button, Col, message, Row, Tooltip, Typography} from "antd";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import EditPencilIcon from "../../components/CustomIcons/EditPencilIcon";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import {DeleteOutlined, FallOutlined, IssuesCloseOutlined, RedoOutlined, RetweetOutlined} from "@ant-design/icons";


const MachineListPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (item)=>{
    return <Row className={'cardHeader'} style={{alignItems:'center'}} key={'cardHeader'}>
            <Tooltip title={item? item[config.cardTitleField]: ''} placement={'bottomLeft'}>
                <Col key={'cardTitle'} span={16} style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize:'20px', overflow:'hidden', textOverflow:'ellipsis'}}>{item? item[config.cardTitleField]: ''}</Col>
            </Tooltip>
            <Col key={'cardButtons'} span={8} style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
                {(!config.disableDelete && !item?.machine_status?.status && item?.machine_status?.status_text != 'notactive_label') ?
                    <CustomConfirmPopup
                        title={config.deleteTitle || "Are you sure to delete this instance?"}
                        onConfirm={()=>handleDeleteClick(item)}
                        icon={<RetweetOutlined style={{color: '#377EF3'}}/>}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={config.deletePermission || []}><Typography.Link style={{color: config.disableDelete ? 'grey': 'blue'}}><RetweetOutlined style={{color: '#377EF3'}}/></Typography.Link></PermissionChecker>
                    </CustomConfirmPopup> : null}
            </Col>
        </Row>;
  }

  const buildCardBody = (data)=>{
    const cardBodyFields = {
      'number': t('machine_carnumber_field'),
      'machine_type_text': t('machine_type_field'),
      'partner_name':  t('machine_owner_field'),
      'active_machine.start_date': t('machine_leasestart_field'),
      'active_machine.end_date': t('machine_leasend_field'),
    };
    let innerContents = [];
    if(cardBodyFields) {
      for (const key in cardBodyFields) {
        const nestedKeys = key.split(".");
        let value = data;
        for (const nestedKey of nestedKeys) {
          value = value[nestedKey];
          if (value === undefined) {
            break;
          }
        }
        if( key === 'machine_type_text'){
          innerContents.push(
              <Row
                  key={key}
                  style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cardBodyFields[key]}: {t(value)}</span>
              </Row>)
        }
        else{
          innerContents.push(
              <Row
                  key={key}
                  style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cardBodyFields[key]}: {value}</span>
              </Row>)
        }
      }
    }
    return <Row style={{flexDirection:'column', justifyContent:'start'}} key={'cardContent'}>

      {innerContents}

    </Row>
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color: data.machine_status.color}}>{t(data.machine_status.status_text)}</label>]
  }

  const getCardBackgroundColor=(data)=>{
        if(data.machine_status.status === 1){
            return '#dfede2'
        }
        else if(data.machine_status?.status_text === 'notactive_label'){
          return '#FAA0A0'
        }
    }

  const config = {
    pageSize: props.pageSize || 10,
    url: props.url || `/api/v1/machines/`,
    hideEdit: props.hideEdit || true,
    hideAddButton: true,
    pageTitle: props.title || t('machine_listpage_title'),
    disableDelete: props.hideDelete,
    deleteTitle: t('machine_return_confirmation_text'),
    pageSubtitle: t('machine_listpage_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
      'active_machine.machine_data.number': t('machine_carnumber_field'),
      'partner_name':  t('machine_owner_field'),
      'active_machine.start_date': t('machine_leasestart_field'),
      'active_machine.end_date': t('machine_leasend_field'),
    },
    cardFooterFields: [],
    addPermission: ['machine-create'],
    editPermission: ['machine-edit'],
    deletePermission: ['machine-return'],
    viewPermission: ['machine-detail'],
    orderingFields: [
      {id: '-name', name: t('machine_orderby_field1')},
      {id: 'name', name: t('machine_orderby_field2')},
      {id: '-number', name: t('machine_orderby_field3')},
      {id: 'number', name: t('machine_orderby_field4')},
      {id: '-created', name: t('machine_orderby_field5')},
      {id: 'created', name: t('machine_orderby_field6')},
    ],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    'getCardBackgroundColor': getCardBackgroundColor,
  }

  const handleDeleteClick = (item)=>{
    if(item.active){
      http.post(`/api/v1/machines/${item.id}/return/`).then((resp)=>{
        if(resp.status === 200){
          message.success(t('machine_return_successful_text'))
          setReload(!reload);
        }
        else{
          message.error((resp.data.error ? t(resp.data.error) : '') || t('machine_return_operation_failed_error'))
        }
      }).catch((err)=>{
        if(err.response){
          message.error((err.response.data.message ? t(err.response.data.message) : '') || (err.response.data.error ? t(err.response.data.error): '') || 'An error occurred, please' +
              ' contact support team!');
        }
      })
    }
    else{
      message.error(t('cancelledmachine_cancelt_try_error'))
    }
  }

  const handleDetailClick=(item)=>{
    navigate('/machine/detail/', { replace: true, state: {machine: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            // onAddClick={handleOpenModal}
            // onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default MachineListPage;
