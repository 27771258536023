import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Row, Col, message} from 'antd';
import classes from "./LoginPage.module.css";
import {login} from "../../actions/actions";
import {logout} from "../../actions/actions";
import {useDispatch, useSelector} from "react-redux";
import logo from "../../assets/icons/logo.png"
import {useTranslation} from "react-i18next";
import {changeLanguage} from "../../utilities/i18next_utils";

const LoginPage = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [status, setStatus] = useState({});
    const { i18n, t } = useTranslation();
    const onFinish = (values) => {
        dispatch(login(values.username, values.password));
    };

    const handleLanguageChange = (lang) => {
        changeLanguage(lang)
        // Add your logic to change the language here
    };

    useEffect(() => {
        if (auth?.error){
            setStatus((prevState) => ({
                ...prevState,
                username: {
                    validateStatus: auth.error.hasOwnProperty('username') ? true : null,
                    errorMsg: auth.error.hasOwnProperty('username') ? t(auth.error.username) : null
                },
                password: {
                    validateStatus: auth.error.hasOwnProperty('password') ? true : null,
                    errorMsg: auth.error.hasOwnProperty('password') ? t(auth.error.password) : null
                }
            }))
            message.error(t(auth?.error?.error))
            dispatch(logout())
        }
    }, [auth?.error])

    return (
        <div style={{overflow: "hidden"}}>
            <Row justify="space-around" gutter={16} className={classes.languageHeader}>
                <Col span={1} xs={2} md={2} sm={2}>
                    <Button className={`${classes.languageButton1} ${i18n.language === 'ja' ? classes.activeLanguage : classes.inactiveLanguage}`} onClick={() => handleLanguageChange('ja')}>日本語</Button>
                </Col>
                <Col span={2} xs={1} md={2} sm={2}>
                    <Button className={`${classes.languageButton2} ${i18n.language === 'en' ? classes.activeLanguage : classes.inactiveLanguage}`} onClick={() => handleLanguageChange('en')}>English</Button>
                </Col>
            </Row>
            <div className={classes.background}>
                <div className={classes.loginDiv}>
                    <Row justify="center" align="middle" gutter={16} style={{ marginBottom: '24px' }}>
                        <Col>
                            <img src={logo} alt="Logo" style={{ height: '48px', marginRight: '16px' }} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={22} justify='center' >
                            <Form
                                name="normal_login"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                style={{ width: '100%' }}
                            >
                                <Form.Item
                                    label={t('login_userid_field_label')}
                                    name="username"
                                    rules={[{ required: true, message: t('login_userid_required_text') }]}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    className={classes.loginFormItem}
                                    validateStatus={status?.username?.validateStatus}
                                    help={status?.username?.errorMsg}
                                >
                                    <Input placeholder={t('login_userid_placeholder_text')} className={classes.inputField} />
                                </Form.Item>
                                <Form.Item
                                    label={t('login_password_field_label')}
                                    name="password"
                                    rules={[{ required: true, message: t('login_password_required_text') }]}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    className={classes.loginFormItem}
                                    validateStatus={status?.password?.validateStatus}
                                    help={status?.password?.errorMsg}
                                >
                                    <Input type="password" placeholder={t('login_password_placeholder_text')} className={classes.inputField} />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className={classes.loginButton}>
                                        {t('login_button_text')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    );
};

export default LoginPage;
