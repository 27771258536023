import {Tabs} from "antd";
import React from "react";
import CompanyInfoSettings from "./CompanyInfoSettings";


const GeneralSettings = (props)=>{
    const tabItems = [
        {
            label: `Company Info`,
            key: 'company',
            children: <CompanyInfoSettings />,
        }
    ]
    return <Tabs
        tabPosition={'left'}
        items={tabItems}
    />
}

export default GeneralSettings;