import dayjs from "dayjs";

export const getReadableTimeFromTimeStamp = (value, format='YYYY-MM-DD HH:mm:ss')=>{
    const date = dayjs(value);

    // Format the date components as a human-readable string
    return date.format(format);
}

const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export const getScreenSize = () => {
    const width = window.innerWidth;
    if (width < breakpoints.sm) {
        return 'xs';
    } else if (width < breakpoints.md) {
        return 'sm';
    } else if (width < breakpoints.lg) {
        return 'md';
    } else if (width < breakpoints.xl) {
        return 'lg';
    } else {
        return 'xl';
    }
};