import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useSelector} from "react-redux";


const GroupAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const auth = useSelector((state) => state.auth);
    const config = {
        addUrl: '/api/v1/auth/groups/add/',
        editUrl: edit ? `/api/v1/auth/groups/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/auth/groups/${editItem?.id}/` : '',
        pageTitle: !edit ? 'Group Add Page' : `Edit ${editItem?.name || ''}`,
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'Group Added Successfully' : 'Group Updated Successfully',
        modalSize: modalSize || 300,
        fields: [
            {
                name: 'tenant',
                label: 'Constructor',
                placeholder: 'Enter Constructor',
                isRequired: false,
                requiredMessage: 'Constructor Selection Mandatory',
                type: 'select',
                extraProps:{
                    url: '/api/v1/tenants/combo/'
                },
                colNo: 1,
                order: 1,
                hidden: !auth.is_superuser
            },
            {
                name: 'name',
                label: 'Group Name',
                placeholder: 'Enter Group Name',
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 1
            }
        ]
    }


    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/auth/groups/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default GroupAddPage;