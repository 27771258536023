import {Divider, Row, Col, Space, Button, Modal, Form, Input, Typography, message} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CheckOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import SelectField from "../../components/Fields/SelectField";
import DateField from "../../components/Fields/DateField";
import http from '../../http';
import MachineProjectListPage from "./MachineProjectListPage";
import DataTable from "../../components/DataTable/DataTable";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";

const MachineDetailPage=(props)=>{
    const location = useLocation();
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [reload, setApplicationReload] = useState(false);
    const [reloadHistory, setHistoryReload] = useState(false);
    const [machine, setMachine] = useState(state?.machine);
    const navigate = useNavigate();
    const [applicationInitialData, setApplicationInitialData] = useState({})
    const [formErrors, setFormErrors] = useState(false);
    const [applicationEdit, setApplicationEdit] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const { t } = useTranslation();
    const [isValidState, setValidState] = useState(!!state?.machine);

    const fetchMachineData=()=>{
        http.get(`/api/v1/machines/${machine?.id}/`).then((resp)=>{
            setLoading(false);
            setMachine(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }
    const fetchDefaultData =()=>{
        fetchMachineData()
        // fetchAssignmentData();
        // fetchApplicationData();
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found', {replace: true})
        }
        if(isValidState) {
            fetchDefaultData()
        }
    }, [state])

    const handleApplicationDelete = (record)=>{
        if(record.status === 0 || record.status === 3){
            http.delete(`/api/v1/machines/rental_applications/${record.id}/delete/`).then((resp)=>{
                if(resp.status === 204){
                    message.success(t('duration_changerequest_delete_successful_text'));
                    fetchDefaultData();
                    setApplicationReload(!reload)
                }
                else{
                    message.error(t('duration_changerequest_delete_failed_text'));
                }
            })
        }
    }

    const handleApplicationEdit = (record)=>{
        setApplicationInitialData({});
        setIsModalVisible(true);
        setApplicationEdit(true);
        setEditItem(record);
        let dataUrl = `/api/v1/machines/rental_applications/${record?.id}/`;
        http.get(dataUrl).then((resp)=>{
            setApplicationInitialData(resp.data);
            setIsModalVisible(true)
            form.setFieldsValue(resp.data);
            setApplicationReload(!reload)

        }).catch((err)=>{
            message.error('Loading Data Failed')
        })
    }

    const renderEditIcon=(record)=>{
        if(record.status === 0 && record.active){
            return <Typography.Link onClick={()=>handleApplicationEdit(record)}><EditOutlined /></Typography.Link>
        }
        return null;
    }

    // const onApplicationPageChange=(current, pageSize)=>{
    //     fetchApplicationData({page: current, page_size:pageSize})
    // }

    // const onAssignmentPageChange=(current, pageSize)=>{
    //     fetchAssignmentData({page: current, page_size:pageSize})
    // }

    const columns = [
        {
            title: t('machine_usagerecord_list_col1'),
            dataIndex: 'from_date',
            key: 'from_date',
        },
        {
            title:  t('machine_usagerecord_list_col2'),
            dataIndex: 'to_date',
            key: 'to_date',
        },
        {
            title: t('machine_usagerecord_list_project_col'),
            dataIndex: 'project_text',
            key: 'project_text',
        },
        {
            title: t('machine_usagerecord_list_col4'),
            dataIndex: 'manifest_text',
            key: 'manifest_name',
        }
    ];

    const handleSubmit=(record)=>{
        try {
            http.post(`/api/v1/machines/rental_applications/${record.id}/submit/`).then((resp)=>{
                if(resp.status === 200){
                    message.success(t('durationchange_request_succesful_text'));
                    fetchDefaultData();
                    setApplicationReload(!reload)
                }
                else{
                    message.error(t('durationchange_request_failed_text'));
                }
            })
        }
        catch (err){
            message.error(t('durationchange_request_failed_text'));
        }
    }

    const handleHistoryMerge=(record)=>{
        try {
            http.post(`/api/v1/machines/histories/${record.id}/merge/`).then((resp)=>{
                if(resp.status === 200){
                    message.success(t('data_merge_label'));
                    setHistoryReload(!reloadHistory);
                    fetchDefaultData();
                }
                else{
                    message.error(t('default_error_connection_text'));
                }
            })
        }
        catch (err){
            message.error(t('default_error_connection_text'));
        }
    }

    const applicationColumns = [
        {
            title: t('duration_changerequest_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
        },
        {
            title: t('duration_changerequest_col2'),
            dataIndex: 'from_date',
            key: 'extension_from_date',
        },
        {
            title: t('duration_changerequest_col3'),
            dataIndex: 'to_date',
            key: 'extension_to_date',
        },
        {
            title: t('duration_changerequest_col4'),
            dataIndex: 'status_text',
            key: 'status',
            render: (text) => (
                <div>{t(text)}</div>
            ),
        },
        {
            title: t('duration_changerequest_col5'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('duration_changerequest_col6'),
            dataIndex: 'triggered_by_text',
            key: 'applied_by',
        },
        {
            title: t('duration_changerequest_col7'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">

                    <PermissionChecker requiredPermissions={['rental-application-edit']}>
                        {renderEditIcon(record)}
                    </PermissionChecker>
                    {!record.status && record.active ? <CustomConfirmPopup
                        title={t('duration_changerequest_submit_confirmation_text')}
                        onConfirm={()=>handleSubmit(record)}
                        icon={<CheckOutlined />}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['rental-application-submit']}>
                            <Typography.Link><CheckOutlined/></Typography.Link>
                        </PermissionChecker>
                    </CustomConfirmPopup> : null}
                    {(record.status === 0 || record.status === 3) && record.active ? <CustomConfirmPopup
                        title={t("duration_changerequest_delete_confirmation_text")}
                        onConfirm={()=>handleApplicationDelete(record)}
                        icon={<DeleteOutlined/>}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['rental-application-delete']}>
                            <Typography.Link><DeleteOutlined/></Typography.Link>
                        </PermissionChecker>
                    </CustomConfirmPopup>: null}
                </Space>
            )
        }
    ]


    const handleApplicationSubmit = (values) => {

        // TODO: Add logic to submit machine assignment to backend
        try {
            if(!applicationEdit){
                http.post('/api/v1/machines/rental_applications/add/', values).then((resp)=>{
                    if(resp.status === 201){
                        message.success(t('durationchange_request_succesful_text'));
                        form.resetFields();
                        fetchDefaultData();
                        setIsModalVisible(false);
                        setApplicationReload(!reload)
                    }
                    else{
                        message.error(t('durationchange_request_failed_text'));
                    }
                }).catch((err)=>{
                    if(err?.response?.status === 400){
                        let errors = {};
                        let err_data = err?.response?.data || {};
                        for (const [key, value] of Object.entries(err_data.error_fields)) {
                            errors[key] = {validateStatus: false, errorMsg: t(value)}
                        }
                        if(Object.keys(errors).length !== 0){
                            message.error(t(err_data?.error) || t('default_error_connection_text'));
                            setFormErrors(errors)
                        }
                        else{
                            message.error(err_data?.validation_error);
                        }
                    }
                    else if(err?.response?.status === 500){
                        let err_data = err?.response?.data || t('default_unknown_error');
                        message.error(err_data?.error || t('default_unknown_error'))
                    }
                })
            }
            else{
                http.patch(`/api/v1/machines/rental_applications/${editItem.id}/edit/`, values).then((resp)=>{
                    if(resp.status === 200){
                        message.success(t('durationchange_request_edit_succesful_text'));
                        form.resetFields();
                        fetchDefaultData();
                        setIsModalVisible(false);
                        setApplicationEdit(false);
                        setEditItem(null);
                        setApplicationReload(!reload)
                    }
                    else{
                        message.error(t('durationchange_request_failed_text'));
                    }
                }).catch((err)=>{
                    if(err?.response?.status === 400){
                        let errors = {};
                        let err_data = err?.response?.data || {};
                        for (const [key, value] of Object.entries(err_data.error_fields)) {
                            errors[key] = {validateStatus: false, errorMsg: value}
                        }
                        if(Object.keys(errors).length !== 0){
                            message.error(t(err_data?.error) || t('default_error_connection_text'));
                            setFormErrors(errors)
                        }
                        else{
                            message.error(err_data?.validation_error);
                        }
                    }
                    else if(err?.response?.status === 500){
                        let err_data = err?.response?.data || t('default_unknown_error');
                        message.error(err_data?.error || t('default_unknown_error'))
                    }
                })
            }
        }

        catch (err){
            message.error(t('durationchange_request_failed_text'));
        }
        //     Modal.error({
        //         title: 'Machine not available',
        //         content: 'The selected machine is not available during the selected time period.',
        //     });
    };

    const renderApplicationForm = () => {
        const validateStatus = formErrors.hasOwnProperty(`to_date`) ? formErrors['to_date']?.validateStatus:null;
        const errorMsg = formErrors.hasOwnProperty(`to_date`) ? formErrors['to_date']?.errorMsg:null;
        return (
            <Modal
                title={Object.keys(applicationInitialData).length === 0  ? t('durationchange_addpage_title'): t('durationchange_editpage_title')}
                open={isModalVisible}
                onCancel={() => {form.resetFields(); setIsModalVisible(false); setFormErrors({})}}
                footer={[
                    <Button key="cancel" onClick={() => {form.resetFields(); setIsModalVisible(false); setFormErrors({})}}>
                        {t('addpage_cancel_button_text')}
                    </Button>,
                    <Button style={{background:'#377EF3'}} key="submit" type="primary" onClick={() => form.submit()}>
                        {Object.keys(applicationInitialData).length === 0 ? t('addpage_save_button_text'): t('addpage_edit_button_text')}
                    </Button>,
                ]}
            >
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col>{t('durationchange_currentstart_date_field')}: {machine.active_machine?.start_date}</Col>
                    <Col>{t('durationchange_currentend_date_field')}: {machine.active_machine?.end_date}</Col>
                </Row>
                <Divider />
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{machine: machine.id}}
                    onFinish={handleApplicationSubmit}
                >
                    <Form.Item label={t('durationchange_machine_field')} name={'machine'}>
                        <div style={{display:"flex"}}>
                            <Form.Item name={'machine'} style={{margin:'0px', width:'100%'}}>
                                <SelectField name={'machine'} url={`/api/v1/machines/combo/?id=${machine.id}`} value={machine.id} />
                            </Form.Item>
                            <div style={{ marginTop: '-4px', marginLeft: '2px', color:'red' }}>*</div>
                        </div>
                    </Form.Item>
                    <Form.Item label={t('durationchange_date_field')} name={'to_date'} validateStatus={validateStatus}
                               help={errorMsg}>
                        <DateField offset={7} initialValue={applicationInitialData['to_date']} />
                    </Form.Item>
                    <Form.Item label={t('durationchange_description_field')} name={'description'}>
                        <Input.TextArea rows={2} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{machine.name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/machines'}>{t('machine_listpage_title')} </Link> >  {machine.name}</>;
    }

    const historyColumns = [
        {
            title: t('lease_history_col1'),
            dataIndex: 'entry_date',
            key: 'entry_date',
            width: '12%'
        },
        {
            title: t('lease_history_col2'),
            dataIndex: 'contract_code',
            key: 'contract_code',
        },
        {
            title: t('lease_history_col3'),
            dataIndex: 'contract_status_text',
            key: 'contract_status_text',
            render: (text) => (
                <div>{t(text)}</div>
            ),
            width: '12%'
        },
        {
            title: t('lease_history_col4'),
            dataIndex: 'start_date',
            key: 'start_date',
            width: '12%'
        },
        {
            title: t('lease_history_col5'),
            dataIndex: 'end_date',
            key: 'end_date',
            width: '12%'
        },
        {
            title: t('lease_history_col6'),
            dataIndex: 'status_text',
            key: 'status_text',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
            ),
            width: '12%'
        },
        {
            title: t('lease_history_col7'),
            dataIndex: 'description',
            key: 'description',
            render: (text) => (
                <div>{t(text)}</div>
            ),
            width: '15%'
        },
        {
            title: t('lease_history_col8'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    {record.dirty && record.contract_status_text === 'active_label' ? <CustomConfirmPopup
                        title="Are you sure to merge this data?"
                        onConfirm={()=>handleHistoryMerge(record)}
                        icon={<CheckOutlined />}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        disabled={false}
                        okType='default'
                    >
                        <PermissionChecker requiredPermissions={['machine-history-merge']}>
                            <Typography.Link style={{color: 'green'}}><CheckOutlined/></Typography.Link>
                        </PermissionChecker>
                    </CustomConfirmPopup> : null}
                </Space>
            )
        }
    ]

    const historySearchFields=[
        {
            name: 'contract_code',
            type: 'input',
            label: 'Contract Code',
            placeholder: t('machine_contract_code_field')
        },
    ]

    if(isValidState) {
        return (
            <>
                {renderApplicationForm(formErrors)}
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                        <Col>
                            {machine.active ?
                                <PermissionChecker requiredPermissions={['rental-application-create']}>
                                    <Button style={{background: '#377EF3'}} type={'primary'} onClick={() => {
                                        setApplicationInitialData({});
                                        setIsModalVisible(true)
                                    }}>{t('durationchange_button_text')}</Button>
                                </PermissionChecker> : null}
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{machine.name}</h1></Col>
                        <Col>
                            <Row style={{display: 'flex', flexDirection: 'row'}}>
                                <Col>
                                    <Col>{t('machine_carnumber_field')}: {machine.number}</Col>
                                    <Col>{t('machine_rentalstart_field')}: {machine.active_machine?.start_date}</Col>
                                    <Col>{t('machine_expectedreturn_field')}: {machine.active_machine?.end_date}</Col>
                                    <Col>{t('machine_status_field')}: {t(machine.machine_status?.status_text)}</Col>
                                </Col>
                                <Divider type='vertical'/>
                                <Col>
                                    <Col>{t('current_attachment_text')} : {machine.default_bucket}</Col>
                                    <Col>{t('other_attachment_text')} : {machine.spare_buckets}</Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MachineProjectListPage pageSize={3} machine={machine} link={[{
                                to: '/machines/',
                                state: {},
                                title: t('machine_listpage_title')
                            }, {to: '/machine/detail/', state: {machine: machine}, title: machine.name}]}/>
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <Row justify="space-between" align="middle" key={'header'}>
                                <Col key={'pageTitle'} span={6} style={{fontWeight: "bold", fontSize: "30px"}}>
                                    {t('machine_positioninformation_listpage_title')}
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <DataTable
                            reload={loading}
                            pageTitle={t('machine_usagerecord_listpage_title')}
                            columns={columns}
                            url={`/api/v1/projects/machine-assignments/?machine=${machine.id}`}
                            searchFields={[]}
                            pagination={true}
                            pageButtons={[]}
                        />
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <DataTable
                            reload={reload}
                            pageTitle={t('machine_durationchangerequestlist_title')}
                            columns={applicationColumns}
                            url={`/api/v1/machines/rental_applications/?machine=${machine.id}`}
                            pagination={true}
                            pageButtons={[]}
                        />
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <DataTable
                            reload={reloadHistory}
                            pageTitle={t('leasehistories_list_title')}
                            columns={historyColumns}
                            url={`/api/v1/machines/histories/?machine=${machine.id}`}
                            searchFields={historySearchFields}
                            pagination={true}
                            pageButtons={[]}
                        />
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default MachineDetailPage;