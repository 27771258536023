import React, {useState, useEffect} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import ProjectMachineAddPage from "./ProjectMachineAddPage";
import {Link, useNavigate} from "react-router-dom";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const ProjectMachineListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
        setReload(!reload)
    }, [props.reload]);

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color: data.machine_status.color}}>{t(data.machine_status.status_text)}</label>]
  }

  const getCardBackgroundColor=(data)=>{
    if(data.machine_status.status === 1){
      return '#dfede2'
    }
  }

  const config = {
    pageSize: props.pageSize || 10,
    url: props.url || `/api/v1/projects/machines/?project=${props.project.id}`,
    pageTitle: props.title || t('machine_listpage_title'),
    pageSubtitle: t('machine_listpage_subtitle'),
    deleteTitle: t('project_machine_delete_confirmation_text'),
    hideEdit: true,
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'machine_text',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
      'machine_status.duration': t('machine_duration_field'),
      'machine_status.manifest_text': t('machine_manifest_field'),
      'machine_status.project_text': t('machine_project_field'),
    },
    cardFooterFields: [],
    addPermission: ['project-machine-create'],
    editPermission: ['project-machine-edit'],
    deletePermission: ['project-machine-delete'],
    viewPermission: ['machine-detail'],
    orderingFields: [
      {id: '-machine__name', name: t('machine_orderby_field1')},
      {id: 'machine__name', name: t('machine_orderby_field2')},
      {id: '-machine__number', name: t('machine_orderby_field3')},
      {id: 'machine__number', name: t('machine_orderby_field4')},
      {id: '-created', name: t('machine_orderby_field5')},
      {id: 'created', name: t('machine_orderby_field6')},
    ],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    'getCardBackgroundColor': getCardBackgroundColor,
  }
  const handleOpenModal = (item, edit) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReload(!reload);
  };
  const handleSuccessAdd = () =>{
    setShowModal(false);
    setReload(!reload);
  }

  const handleDeleteClick = (item)=>{
    if(!item?.machine_status?.status) {
      http.delete(`/api/v1/projects/machines/${item.id}/delete/`).then((resp)=>{
        setReload(!reload);
      }).catch((err)=>{
        if(err.response){
          message.error(err.response.data.message);
        }
      })
    }
    else{
      message.error(t('machine_cancel_failed_text'));
    }
  }

  const handleDetailClick=(item)=>{
    navigate('/machine/detail/', { replace: true, state: {machine: item.machine, breadcrumbs:props.link} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
        <ProjectMachineAddPage
            visible={showModal}
            onClose={handleCloseModal}
            onSuccessSubmit={handleSuccessAdd}
            project={props.project}
            reload={reload}
        />
      </>
  );
};

export default ProjectMachineListPage;
