import {Col, List, Row} from "antd";
import {useEffect, useState} from "react";
import http from "../../http";


const CompanyInfoSettings =(props)=>{
    const [companyInfo, setCompanyInfo] = useState([])
    useEffect(() => {
        http.get(`/api/v1/auth/get_tenant_info/`).then((resp)=>{
            setCompanyInfo(resp.data.data)
        }).catch(()=>{
            console.log("Something Wrong!")
        })
    }, [])

    const getDictData = () => {
        return [
            ['Company Name: ', `${companyInfo.company_name}`],
            ['MGS Code: ', `${companyInfo.identifier}`],
            ['Email: ', `${companyInfo.email}`],
            ['Contact No: ', `${companyInfo.contact}`],
        ]
    }
   return (
        <Row style={{display: 'flex', flexDirection:'column'}}>
            <Col style={{lineHeight: '1.5rem'}}><h1>Company Info</h1></Col>
            <List
                size="large"
                bordered
                dataSource={getDictData()}
                renderItem={(item) => <List.Item>
                    <b>{item[0]}</b> {item[1]}
                </List.Item>}
            />
        </Row>
    );
}

export default CompanyInfoSettings;