import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const ProjectAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem})=>{
    const [initialValues, setInitialValues] = useState({})
    const [defaultValues, setDefaultValues] = useState({})
    const { t } = useTranslation();
    const config = {
        addUrl: '/api/v1/projects/add/',
        editUrl: edit ? `/api/v1/projects/${editItem?.id}/edit/` : '',
        dataUrl: edit ? `/api/v1/projects/${editItem?.id}/` : '',
        pageTitle: !edit ? t('project_addpage_title') : t('addpage_edit_button_text') + ' ' +`${editItem?.project_name}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('project_successful_add_message') : t('project_successful_edit_message'),
        modalSize: 600,
        fields: [
            {
                name: 'project_name',
                label: t('project_name_field'),
                placeholder: t('project_placeholder'),
                isRequired: true,
                requiredMessage: t('project_required_label'),
                type: 'input',
                colNo: 1,
                order: 1
            },
            {
                name: 'site_manager',
                label: t('project_sitemanager_field'),
                placeholder: t('sitemanager_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    url: '/api/v1/auth/users/combo/'
                },
                colNo: 2,
                order: 1
            },
            {
                name: 'start_date',
                label: t('project_start_field'),
                placeholder: 'Start Date',
                isRequired: false,
                requiredMessage: '',
                type: 'date',
                colNo: 1,
                order: 3
            },
            {
                name: 'end_date',
                label: t('project_end_field'),
                placeholder: 'End Date',
                isRequired: false,
                requiredMessage: '',
                type: 'date',
                colNo: 1,
                order: 4,
                extraProps: {
                    offset: 30
                }
            },
            // {
            //     name: 'status',
            //     label: 'Status',
            //     placeholder: 'Status',
            //     isRequired: false,
            //     requiredMessage: '',
            //     type: 'select',
            //     extraProps: {
            //         url: '/api/v1/enums/ProjectChoices/combo/'
            //     },
            //     colNo: 2,
            //     order: 2
            // },
            {
                name: 'area_number',
                label: t('project_jcp_field'),
                placeholder: t('project_jcp_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    data:[{id: 1, name: 1}, {id:2, name: 2}, {id:3, name:3}, {id:4, name:4}, {id:5, name:5}, {id:6, name:6}, {id:7, name:7}, {id:8, name:8}, {id:9, name:9}, {id:10, name:10}, {id:11, name:11}, {id:12, name:12}, {id:13, name:13}, {id:14, name:14}, {id:15, name:15}, {id:16, name:16}, {id:17, name:17}, {id:18, name:18}, {id:19, name:19}]
                },
                colNo: 2,
                order: 2
            },
            // {
            //     name: 'machine_list',
            //     label: 'Machine',
            //     placeholder: 'Please Select',
            //     isRequired: true,
            //     requiredMessage: 'Machine Selection Required',
            //     type: 'select',
            //     extraProps: {
            //         url: '/api/v1/machines/combo/',
            //         mode: 'multiple'
            //     },
            //     colNo: 2,
            //     order: 2
            // },
            // {
            //     name: 'field',
            //     label: 'Field',
            //     placeholder: 'Please Select',
            //     isRequired: true,
            //     requiredMessage: 'Field Selection Required',
            //     type: 'file',
            //     colNo: 2,
            //     order: 3
            // },
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/projects/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues} />

}

export default ProjectAddPage;