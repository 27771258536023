import React from 'react';
import { List } from 'antd';// adjust the path as necessary
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {useData} from "../../DataContext";
import routesList from "./../../routes";

// Example user permission fetch function
const getUserPermissions = (storedData) => {
    return storedData?.permitted_urls || window.sessionStorage.getItem('permissions') || [];
}

const NotificationContent = (props) => {
    const {sharedData} = useData();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const checkPermission = (redirectUrl) => {
        const route = routesList.find((r) => r.path === redirectUrl);
        const userPermissions = getUserPermissions(sharedData);
        return route ? route.requiredPermission?.some((perm) => userPermissions.includes(perm)) : false;
    };

    const handleNotificationClick = (notification) => {
        navigate(notification.redirect_url, { state: notification.stateData });
    };

    return (
        <div style={{ maxHeight: '300px', overflowY: 'auto'}}>
        <List
            dataSource={props.notifications}
            size="small"
            renderItem={(notification) => {
                const hasPermission = checkPermission(notification.redirect_url);
                const key = Object.keys(notification?.stateData)[0]
                const messageData = notification?.stateData[key];
                if (!hasPermission) return null;
                return (
                    <List.Item onClick={() => handleNotificationClick(notification)} style={{ padding: '8px 16px', cursor: 'pointer' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ marginBottom: '4px' }} >{t(notification.title, {...messageData})}</h4>
                            <p style={{ fontSize: '12px', color: '#888' }}>{t(notification.message, {...messageData})}</p>
                        </div>
                    </List.Item>
                );
            }}
        />
            </div>
    );
};

export default NotificationContent;
