import http from '../http';
import {LOGOUT_USER, LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST, SET_SPINNER, REMOVE_SPINNER} from "./types";


// action creators
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const loginSuccess = (user_id, is_superuser, username) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user_id, is_superuser, username },
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: { error },
  };
};

// thunk action creator
export const login = (username, password) => {
  return async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await http.post('/o/login/', {
        username,
        password,
      });
      let payload = response.data;
      sessionStorage.setItem('isSuperuser', payload.data.is_superuser);
      sessionStorage.setItem('isAdminUser', payload.data.is_admin);
      sessionStorage.setItem('permissions', payload.data.permitted_urls);
      dispatch(loginSuccess( payload.data.user_id, payload.data.is_superuser, payload.data.username));
    } catch (error) {
      dispatch(loginFailure(error?.response?.data));
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem('reduxState');
    sessionStorage.removeItem('isSuperuser');
    sessionStorage.removeItem('isAdminUser');
    sessionStorage.removeItem('permissions');
    dispatch({ type: LOGOUT_USER });
    // Perform logout logic here...
  };
};

export const setSpinner = () => {
  return (dispatch) => {
    dispatch({ type: SET_SPINNER });
    // Perform logout logic here...
  };
};

export const removeSpinner = () => {
  return (dispatch) => {
    dispatch({ type: REMOVE_SPINNER });
    // Perform logout logic here...
  };
};

